import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"

export const SectionPayments = ({ title, description, items, handlerModalOperator}) => {
  const { t } = useTranslation()

  return (
    <div className="SectionPayments">
      <div className="SectionPayments__content">
        <h2 className="title">{title}</h2>
        <div className="SectionPayments__content__cards">
          {items?.map((item, index) => (
            <div key={index} className="card" data-aos="fade-up">
              <div className="card__left">
                <img className="image" src={item.image} alt="pagos lifter" />
              </div>
              <div className="card__right">
                <h4 className="min-title">{item.title}</h4>
                <p className="text">{item.description?.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="SectionPayments__content--fotter">
          <h2 className="title">{description}</h2>
          <div className="cont-btn">
            <Button className="btn btn--primary" onClick={() => handlerModalOperator(true)}>
              {t("button.revolutionize_deliveries")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
