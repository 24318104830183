import React from "react"
import Slider from "react-slick"

export const SectionHowMake = ({ title, description, items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className="SectionHowMake">
      <div className="SectionHowMake--left">
        <h1 className="title">{title}</h1>
        <p>{description}</p>
      </div>
      <div className="SectionHowMake--right">
        <Slider {...settings}>
          {items?.map((item, index) => (
            <div key={index} className="carrucel">
              <div className="SectionHowMake--right--caroucel">
                <img src={item.image} alt="ImagenTutorial" />
                <h2>{item.title}</h2>
                <p>{item.description?.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
