import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "antd"

import { PhoneLines } from "./components/PhoneLines/PhoneLines"

export const SectionBannerOperators = ({ title, description, image, handlerModalOperator }) => {
  const { t } = useTranslation()

  return (
    <div className="Operators__banner--center">
      <div className="Operators__banner__left">
        <h1 className="title">{title}</h1>
        <p className="text">{description}</p>
        <div className="cont-btn">
          <Button
            className="btn btn--primary"
            onClick={() => handlerModalOperator(true)}
          >
            {t("button.revolutionize_deliveries")}
          </Button>
        </div>
      </div>
      <PhoneLines image={image} />
    </div>
  )
}
