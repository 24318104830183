import React, { useState, useMemo } from "react"
import Slider from "react-slick";

export const SectionBusinessLines = ({ title, description, items }) => {
  const [itemsCustom, setItemsCustom] = useState()
  const [itemSelected, setItemSelected] = useState(items[0])

  useMemo(() => {
    const parserItems = items.map(item => {
      if (typeof item.metada[0]?.internal.content === "string")
        item.backgroundColor = JSON.parse(
          item.metada[0]?.internal.content
        ).color
      return item
    })

    setItemsCustom(parserItems)
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  console.log(items)

  return (
    <div className="SectionBusinessLines">
      <div className="SectionBusinessLines__content">
        <h1 className="title">{title}</h1>
        <p className="text">{description}</p>
        <div className="SectionBusinessLines__content__card">
          {itemsCustom?.map((item, index) => (
            <div key={index}>
              {itemSelected.key === item.key && (
                <div className="card" >
                  <div
                    style={{ background: item.backgroundColor }}
                    className="card-img"
                  >
                    <img
                      className="brands animate__animated animate__fadeIn"
                      src={item.image}
                      alt="ImageBrand"
                    />
                  </div>
                  <div className="card__body animate__animated animate__fadeIn">
                    <p className="card__body--text">
                      {item.description?.description}
                    </p>
                    {item.author && (
                      <div className="card__body--data">
                        <img
                          className="avatar"
                          src={item.author.photo}
                          alt="Avatar"
                        />
                        <div>
                          <h2 className="name">
                            {`${item.author.name} ${item.author.lastname}`}
                          </h2>
                          <p className="rol">{item.author.profile}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="SectionBusinessLines__content__brands">
          {items?.map((item, index) => (
            <div
              onClick={() => setItemSelected(item)}
              key={index}
              className="card-brand"
            >
              <img className="brand" src={item.image_white} alt="Brand" />
            </div>
          ))}
        </div>

        <div className="SectionBusinessLines__content__brands--res">
          <Slider {...settings}>
            {items?.map((item, index) => (
              <div key={index} className="section-c">
                <div className="card-2">
                  <div className="img" style={{ backgroundColor: `${item.backgroundColor}` }}>
                    <img className="img--img" src={item.image} alt={item.name}/>
                  </div>
                  <div className="cardBody">
                    <p>{item.description.description}</p>
                    <div>
                    {item.author && (
                      <div className="card__body--data">
                        <img
                          className="avatar"
                          src={item.author.photo}
                          alt="Avatar"
                        />
                        <div>
                          <h2 className="name">
                            {`${item.author.name} ${item.author.lastname}`}
                          </h2>
                          <p className="rol">{item.author.profile}</p>
                        </div>
                      </div>
                    )}
                    </div>
                  </div>
                </div>
                <div className="card-brand">
                  <img className="brand" src={item.image_white} alt="Brand" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
