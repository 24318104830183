/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect } from "react"

export const PhoneLines = ({ image }) => {
  // particle refs
  const topTopRef = useRef()
  const topLeftRef = useRef()
  const topMediumRef = useRef()
  const topLeftBottomLeftRef = useRef()
  const topLeftBottomRef = useRef()
  const rightMediumRef = useRef()
  const rightBottomRef = useRef()
  const rightTopRef = useRef()
  // line flash refs
  const leftTopFlashRef = useRef()
  const leftMediumFlashRef = useRef()
  const leftBottomLeftFlashRef = useRef()
  const bottomFlashRef = useRef()
  const rightMediumFlashRef = useRef()
  const mediumLeftFlashRef = useRef()
  const rightTopFlashRef = useRef()
  const topFlashRef = useRef()

  const FLASH_SLEEP = 4000

  useEffect(() => {
    const wrapperTopTop = topTopRef.current
    wrapperTopTop !== null &&
      setInterval(() => {
        wrapperTopTop.classList.toggle("c-right-top-top-particle")
      }, FLASH_SLEEP)
  }, [topTopRef])

  useEffect(() => {
    const wrapperTopLeft = topLeftRef.current
    wrapperTopLeft !== null &&
      setInterval(() => {
        wrapperTopLeft.classList.toggle("c-top-left-particle")
      }, FLASH_SLEEP)
  }, [topLeftRef])

  useEffect(() => {
    const wrapperMedium = topMediumRef.current
    wrapperMedium !== null &&
      setInterval(() => {
        wrapperMedium.classList.toggle("c-top-medium-particle")
      }, FLASH_SLEEP)
  }, [topMediumRef])

  useEffect(() => {
    const wrapperTopLeftBottomLeft = topLeftBottomLeftRef.current
    wrapperTopLeftBottomLeft !== null &&
      setInterval(() => {
        wrapperTopLeftBottomLeft.classList.toggle(
          "c-top-left-bottom-left-particle"
        )
      }, FLASH_SLEEP)
  }, [topLeftBottomLeftRef])

  useEffect(() => {
    const wrapperTopLeftBottomRef = topLeftBottomRef.current
    wrapperTopLeftBottomRef !== null &&
      setInterval(() => {
        wrapperTopLeftBottomRef.classList.toggle("c-top-left-bottom-particle")
      }, FLASH_SLEEP)
  }, [topLeftBottomRef])

  useEffect(() => {
    const wrapperRightMediumRef = rightMediumRef.current
    wrapperRightMediumRef !== null &&
      setInterval(() => {
        wrapperRightMediumRef.classList.toggle("c-right-medium-particle")
      }, FLASH_SLEEP)
  }, [rightMediumRef])

  useEffect(() => {
    const wrapperRightBottomRef = rightBottomRef.current
    wrapperRightBottomRef !== null &&
      setInterval(() => {
        wrapperRightBottomRef.classList.toggle("c-right-bottom-particle")
      }, FLASH_SLEEP)
  }, [rightBottomRef])

  useEffect(() => {
    const wrapperRightTopRef = rightTopRef.current
    wrapperRightTopRef !== null &&
      setInterval(() => {
        wrapperRightTopRef.classList.toggle("c-right-top-particle")
      }, FLASH_SLEEP)
  }, [rightTopRef])

  useEffect(() => {
    const wrapperLeftTopFlashRef = leftTopFlashRef.current
    wrapperLeftTopFlashRef !== null &&
      setInterval(() => {
        wrapperLeftTopFlashRef.classList.toggle("left-top-flash")
      }, FLASH_SLEEP)
  }, [leftTopFlashRef])

  useEffect(() => {
    const wrapperLeftMediumFlashRef = leftMediumFlashRef.current
    wrapperLeftMediumFlashRef !== null &&
      setInterval(() => {
        wrapperLeftMediumFlashRef.classList.toggle("left-medium-flash")
      }, FLASH_SLEEP)
  }, [leftMediumFlashRef])

  useEffect(() => {
    const wrapperLeftBottomLeftFlashRef = leftBottomLeftFlashRef.current
    leftBottomLeftFlashRef !== null &&
      setInterval(() => {
        wrapperLeftBottomLeftFlashRef.classList.toggle("left-bottom-left-flash")
      }, FLASH_SLEEP)
  }, [leftBottomLeftFlashRef])

  useEffect(() => {
    const wrapperBottomFlashRef = bottomFlashRef.current
    wrapperBottomFlashRef !== null &&
      setInterval(() => {
        wrapperBottomFlashRef.classList.toggle("bottom-flash")
      }, FLASH_SLEEP)
  }, [bottomFlashRef])

  useEffect(() => {
    const wrapperRightMediumFlashRef = rightMediumFlashRef.current
    wrapperRightMediumFlashRef !== null &&
      setInterval(() => {
        wrapperRightMediumFlashRef.classList.toggle("rigth-medium-flash")
      }, FLASH_SLEEP)
  }, [rightMediumFlashRef])

  useEffect(() => {
    const wrapperMediumLeftFlashRef = mediumLeftFlashRef.current
    wrapperMediumLeftFlashRef !== null &&
      setInterval(() => {
        wrapperMediumLeftFlashRef.classList.toggle("medium-left-flash")
      }, FLASH_SLEEP)
  }, [mediumLeftFlashRef])

  useEffect(() => {
    const wrapperRightTopFlashRef = rightTopFlashRef.current
    wrapperRightTopFlashRef !== null &&
      setInterval(() => {
        wrapperRightTopFlashRef.classList.toggle("rigth-top-flash")
      }, FLASH_SLEEP)
  }, [rightTopFlashRef])

  useEffect(() => {
    const wrapperTopFlashRef = topFlashRef.current
    wrapperTopFlashRef !== null &&
      setInterval(() => {
        wrapperTopFlashRef.classList.toggle("top-flash")
      }, FLASH_SLEEP)
  }, [topFlashRef])

  return (
    <div className="Operators__banner__right">
      <div className="rayos">
        <svg
          className="svg top"
          width="96"
          height="153"
          viewBox="0 0 96 153"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.85433 152.291C0.886141 145.496 1.31676 128.145 18.7847 113.102C40.6197 94.2987 89.4011 15.7746 93.8322 1.41772"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="39.13"
              y1="8.73865"
              x2="57.3655"
              y2="144.996"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#21233B" stopOpacity="0" />
              <stop offset="0.778929" stopColor="#57FFFE" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={topFlashRef}
          className="svg"
          width="96"
          height="153"
          viewBox="0 0 96 153"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.85433 152.291C0.886141 145.496 1.31676 128.145 18.7847 113.102C40.6197 94.2987 89.4011 15.7746 93.8322 1.41772"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="39.13"
              y1="8.73865"
              x2="57.3655"
              y2="144.996"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#21233B" stopOpacity="0" />
              <stop offset="0.778929" stopColor="#57FFFE" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          className="svg left-top"
          width="219"
          height="176"
          viewBox="0 0 219 176"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M216.86 174.305L139.244 95.754C126.152 79.8567 94.5447 46.7528 72.8497 41.516C45.7309 34.9701 9.26077 15.3326 2.71484 1.30566"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="109.787"
              y1="1.30566"
              x2="109.787"
              y2="174.305"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D2F49" stopOpacity="0" />
              <stop offset="0.572917" stopColor="#57FFFE" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={leftTopFlashRef}
          className="svg"
          width="219"
          height="176"
          viewBox="0 0 219 176"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M216.86 174.305L139.244 95.754C126.152 79.8567 94.5447 46.7528 72.8497 41.516C45.7309 34.9701 9.26077 15.3326 2.71484 1.30566"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="109.787"
              y1="1.30566"
              x2="109.787"
              y2="174.305"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D2F49" stopOpacity="0" />
              <stop offset="0.572917" stopColor="#57FFFE" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          className="svg rigth-top"
          width="195"
          height="207"
          viewBox="0 0 195 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.52441 205.151L155.444 61.9925C161.755 47.1528 178.062 14.4032 192.799 2.12207"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="103.505"
              y1="2.12207"
              x2="103.505"
              y2="191.456"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D2F49" stopOpacity="0" />
              <stop offset="0.679935" stopColor="#57FFFE" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={rightTopFlashRef}
          className="svg"
          width="195"
          height="207"
          viewBox="0 0 195 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.52441 205.151L155.444 61.9925C161.755 47.1528 178.062 14.4032 192.799 2.12207"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="103.505"
              y1="2.12207"
              x2="103.505"
              y2="191.456"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D2F49" stopOpacity="0" />
              <stop offset="0.679935" stopColor="#57FFFE" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          className="svg left-medium"
          width="149"
          height="179"
          viewBox="0 0 149 179"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M147.682 176.927L100.652 145.067L65.8746 115.083C52.7828 99.1855 42.2488 107.242 27.9501 90.1061C9.19657 67.6314 8.35745 15.4396 1.81152 1.4126"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="40.6097"
              y1="3.92769"
              x2="40.6097"
              y2="176.927"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D2F49" stopOpacity="0" />
              <stop offset="0.510417" stopColor="#57FFFE" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={leftMediumFlashRef}
          className="svg"
          width="149"
          height="179"
          viewBox="0 0 149 179"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M147.682 176.927L100.652 145.067L65.8746 115.083C52.7828 99.1855 42.2488 107.242 27.9501 90.1061C9.19657 67.6314 8.35745 15.4396 1.81152 1.4126"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="40.6097"
              y1="3.92769"
              x2="40.6097"
              y2="176.927"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2D2F49" stopOpacity="0" />
              <stop offset="0.510417" stopColor="#57FFFE" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          width="292"
          height="214"
          className="svg left-bottom-left"
          viewBox="0 0 292 214"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M252.65 2.22838C233.332 25.66 146.012 69.335 130.162 90.0801C110.35 116.012 92.4274 140.852 65.5367 156.377C53.5798 163.281 56.3935 200.788 37.9146 211.457"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="216.524"
              y1="33.8181"
              x2="-3.21976"
              y2="197.947"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0208333" stopColor="#47FFFE" />
              <stop offset="1" stopColor="#2D2F49" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={leftBottomLeftFlashRef}
          width="292"
          height="214"
          className="svg"
          viewBox="0 0 292 214"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M252.65 2.22838C233.332 25.66 146.012 69.335 130.162 90.0801C110.35 116.012 92.4274 140.852 65.5367 156.377C53.5798 163.281 56.3935 200.788 37.9146 211.457"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="216.524"
              y1="33.8181"
              x2="-3.21976"
              y2="197.947"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0208333" stopColor="#47FFFE" />
              <stop offset="1" stopColor="#2D2F49" stopOpacity="0" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          className="svg rigth-medium"
          width="117"
          height="191"
          viewBox="0 0 117 191"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.52637 1.50195L47.9061 75.023C51.3492 78.117 59.6811 90.975 65.4634 117.655C71.2457 144.336 101.179 176.413 115.423 189.116"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="-21.8204"
              y1="-25.3761"
              x2="116.376"
              y2="194.419"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.203125" stopColor="#57FFFE" />
              <stop offset="1" stopColor="#2D2F49" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={rightMediumFlashRef}
          className="svg"
          width="117"
          height="191"
          viewBox="0 0 117 191"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.52637 1.50195L47.9061 75.023C51.3492 78.117 59.6811 90.975 65.4634 117.655C71.2457 144.336 101.179 176.413 115.423 189.116"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="-21.8204"
              y1="-25.3761"
              x2="116.376"
              y2="194.419"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.203125" stopColor="#57FFFE" />
              <stop offset="1" stopColor="#2D2F49" stopOpacity="0" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          className="svg bottom"
          width="266"
          height="246"
          viewBox="0 0 266 246"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M264.317 1.42188C226.051 39.3362 2 244 2 244"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="306.685"
              y1="1.42203"
              x2="78.1729"
              y2="179.94"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.135417" stopColor="#57FFFE" />
              <stop offset="1" stopColor="#2D2F49" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={bottomFlashRef}
          className="svg"
          width="266"
          height="246"
          viewBox="0 0 266 246"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M264.317 1.42188C226.051 39.3362 2 244 2 244"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="306.685"
              y1="1.42203"
              x2="78.1729"
              y2="179.94"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.135417" stopColor="#57FFFE" />
              <stop offset="1" stopColor="#2D2F49" stopOpacity="0" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          className="svg medium-left"
          width="206"
          height="222"
          viewBox="0 0 206 222"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.91162 1.67773L203.589 220.217"
            stroke="url(#paint0_linear)"
            strokeWidth="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="108.825"
              y1="15.6282"
              x2="108.825"
              y2="220.218"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#57FFFE" />
              <stop offset="1" stopColor="#292B44" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          ref={mediumLeftFlashRef}
          className="svg"
          width="206"
          height="222"
          viewBox="0 0 206 222"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.91162 1.67773L203.589 220.217"
            stroke="url(#paint0_linear)"
            strokeWidth="4"
            filter="url(#blurMe)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="108.825"
              y1="15.6282"
              x2="108.825"
              y2="220.218"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#57FFFE" />
              <stop offset="1" stopColor="#292B44" stopOpacity="0" />
            </linearGradient>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            </filter>
          </defs>
        </svg>

        <svg
          className="circle c-top-left animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>
        <svg
          ref={topLeftRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          ref={topMediumRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          ref={topLeftBottomLeftRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          ref={topLeftBottomRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          ref={rightMediumRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          ref={rightBottomRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          ref={rightTopRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          ref={topTopRef}
          className="circle"
          width="10"
          height="10"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="blurMe">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <circle filter="url(#blurMe)" cx="39" cy="39" r="32" fill="#57FFFE" />
        </svg>
        <svg
          className="circle c-top-2 animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>
        <svg
          className="circle c-top-rigth animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>
        <svg
          className="circle c-bottom animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>

        <svg
          className="circle c-bottom-left animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>
        <svg
          className="circle c-bottom-right animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>
        <svg
          className="circle c-top animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>
        <svg
          className="circle c-right-bottom-top animated-circle"
          width="30"
          height="30"
          viewBox="0 0 77 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="32" fill="#57FFFE" />
          <circle
            cx="38.5"
            cy="38.5001"
            r="19.5"
            fill="#57FFFE"
            fillOpacity="0.18"
          />
        </svg>
      </div>
      <img className="phone" src={image} alt="phone" />
    </div>
  )
}
