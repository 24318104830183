import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"

import CountNumber from "../../Cards/CardsCount/CardsCount"
import logo from "../../../assets/images/logoLif.png"
import radio from "../../../assets/images/radio.png"

export const SectionMapsAllies = ({ title, description, items, accountat }) => {
  const { t } = useTranslation()
  const [sectionTabs, inSectionTabs] = useInView({ threshold: 0 })
  const [point, setPoint] = useState(false)

  useEffect(() => {
    if (!inSectionTabs) setPoint(false)
    document.body.style.overflowX = "hidden"
  }, [inSectionTabs])

  const handlePoint = () => {
    setPoint(true)
  }

  return (
    <div
      className={`SectionMapsAllies  ${
        inSectionTabs && "SectionMapsAllies--map"
      }`}
    >
      <div className="SectionMapsAllies__content">
        <div className="SectionMapsAllies__content--text">
          <div>
            <h1 className="title">{title}</h1>
            <p className="text">{description}</p>
          </div>
          {point && (
            <div className="card-count-cont animate__animated animate__fadeInLeft">
              <CountNumber accountant={accountat} />
            </div>
          )}
        </div>
        <div className="SectionMapsAllies__content--animated">
          <div
            className={`talkbubble animate__fadeIn animate__animated ${
              point && "talkbubble--true"
            } ${inSectionTabs && "talkbubble--anime"}`}
          >
            {point ? (
              <span>{t("general.good")}</span>
            ) : (
              <span>{t("general.clickme")}</span>
            )}
          </div>
          {point && (
            <svg
              className={`svg--ruta  ${
                inSectionTabs && "svg--ruta--true ruta-animated"
              }`}
              width="485"
              height="420"
              viewBox="0 0 485 420"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1H83V15L345 56.5L372 45.5L483.5 88L433.5 210H421L409.5 229L433.5 275.5L330 419"
                stroke="#FF7A57"
                strokeWidth="2"
              />
            </svg>
          )}

          {items && (
            <>
              <div
                className={`Location Location--top  ${
                  inSectionTabs && "Location--animated"
                }`}
              >
                <div className="point point--top">
                  <div className="point__circle" />
                </div>
                <div className="card">
                  <div className="card--point" />
                  <p>{items[0].title}</p>
                </div>
              </div>
              <div
                className={`Location Location--bottom  ${
                  inSectionTabs && "Location--animated"
                }`}
              >
                <div className="point point--bottom">
                  <div className="point__circle" />
                </div>
                <div className="card">
                  <div className="card--point" />
                  <p>{items[1].title}</p>
                </div>
              </div>
              <div
                className={`Location Location--rigth  ${
                  inSectionTabs && "Location--animated"
                }`}
              >
                <div className="point point--rigth">
                  <div className="point__circle" />
                </div>
                <div className="card">
                  <div className="card--point" />
                  <p>{items[2].title}</p>
                </div>
              </div>
            </>
          )}

          <div>
            <div
              className={`circle circle--max  ${
                inSectionTabs && "circle--max--scale"
              } ${point && "circle--max--point"}`}
            />
            <div className="circle">
              <div
                className={`circle circle--white ${
                  inSectionTabs && "circle--white--rotate"
                }`}
                ref={sectionTabs}
              >
                <img src={radio} alt="radio" className="radio radio--rotate" />
              </div>
              <div>
                <div onClick={() => handlePoint()} className="pulso">
                  <img
                    src={logo}
                    alt="logo"
                    className={`logo ${point && "logo--point"}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
