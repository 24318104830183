import React, { useState } from "react"
import { useSelector } from "react-redux"
import BackgroundImage from "gatsby-background-image"
import back from "../../assets/images/background-lines.jpg";

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { SectionBannerOperators } from "../../components/PageOperators/SectionBannerOperators/SectionBannerOperators"
import { SectionHowMake } from "../../components/PageOperators/SectionHowMake/SectionHowMake"
import { SectionPayments } from "../../components/PageOperators/SectionPayments/SectionPayments"
import { SectionBusinessLines } from "../../components/PageOperators/SectionBusinessLines/SectionBusinessLines"
import { SectionMapsAllies } from "../../components/PageOperators/SectionMapsAllies/SectionMapsAllies"
import { SectionTabsInformation } from "../../components/SectionTabsInformation/SectionTabsInformation"
import PreviewBlog from "../../components/PreviewBlog/PreviewBlog";
import SEO from "../../components/seo"
import { ModalNewLead } from "../../components/Modals/ModalNewLead/ModalNewLead";

const Operators = ({ data: { contentfulPaginaOperadores } }) => {
  if (!contentfulPaginaOperadores) return null

  const [modalOperators, setModalOperators] = useState(false)
  const { languageISO639 } = useSelector(state => state.language)

  const {
    seo,
    section_banner,
    banner_image_secondary,
    banner,
    section_2,
    section_2_items,
    section_3_items,
    section_4,
    section_4_items,
    section_5,
    section_5_items,
    section_6,
    section_6_items,
  } = contentfulPaginaOperadores

  return (
    <Layout fix="layout-fix">
      {seo && <SEO {...seo} lang={languageISO639} />}

      <ModalNewLead
        handlerClose={(newState) => setModalOperators(newState)}
        visible={modalOperators}
        keyForm="form_operator"
      />

      <div className="Operators">
        <div className="Operators__banner">
          <div className="background">
            <img className="img" src={back} />
          </div>
          <SectionBannerOperators
            title={section_banner.title}
            description={section_banner.description?.description}
            image={banner_image_secondary}
            banner={banner.fluid}
            handlerModalOperator={setModalOperators}
          />
          <SectionHowMake
            title={section_2.title}
            description={section_2.description?.description}
            items={section_2_items}
          />
        </div>
        <SectionTabsInformation items={section_3_items} video />
        <SectionPayments
          title={section_4.title}
          description={section_4.description?.description}
          items={section_4_items}
          handlerModalOperator={setModalOperators}
        />
        <SectionBusinessLines
          title={section_5.title}
          description={section_5.description?.description}
          items={section_5_items}
        />
        <SectionMapsAllies
          title={section_6.title}
          description={section_6.description?.description}
          accountat={section_6.metada}
          items={section_6_items}
        />
        <PreviewBlog />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageOperators($locale: String) {
    contentfulPaginaOperadores(language: { language: { eq: $locale } }) {
      seo {
        ...SEOFragment
      }
      section_banner {
        ...SectionFragment
      }
      banner {
        fluid(maxWidth: 1920, quality: 80) {
          sizes
          src
          srcSet
          base64
          aspectRatio
        }
      }
      banner_image_secondary
      section_2 {
        ...SectionFragment
      }
      section_2_items {
        ...SectionFragment
      }
      section_3_items {
        ...SectionFragment
      }
      section_4 {
        ...SectionFragment
      }
      section_4_items {
        ...SectionFragment
      }
      section_5 {
        ...SectionFragment
      }
      section_5_items {
        ...SectionFragment
      }
      section_6 {
        ...SectionFragment
      }
      section_6_items {
        ...SectionFragment
      }
    }
  }
`

export default Operators
